<template>
  <form
    ref="form"
    @submit.prevent="sendConseilLocal"
  >
    <h1 class="title">
      Informations générales
    </h1>
    <div>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="conseilLocal.nom"
            v-validate="'required|min:3|max:50'"
            label="Nom *"
            data-vv-name="nom"
            :error-messages="errors.collect('nom')"
            :disabled="isDisplayMode"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="conseilLocal.statut_juridique"
            v-validate:statutJuridiqueId="{ is_not: 0 }"
            :items="statutsJuridique"
            item-text="nom"
            label="Statut juridique *"
            data-vv-name="statut juridique"
            :error-messages="errors.collect('statut juridique')"
            return-object
            :disabled="isDisplayMode"
          />
        </v-col>
        <v-col
          cols="3"
        >
          <v-text-field
            v-model="nomCourt"
            label="Nom court"
            disabled
            readonly
          />
        </v-col>
        <v-col
          v-if="editMode"
          cols="3"
        >
          <v-text-field
            v-model="conseilLocal.code"
            label="Code"
            disabled
            readonly
          />
        </v-col>
      </v-row>
    </div>

    <div>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="conseilLocal.degre_enseignement"
            v-validate:degreEnseignementId="{ is_not: 0 }"
            :items="currentDegresEnseignement"
            item-text="libelle"
            label="Degré d'enseignement *"
            data-vv-name="degré d'enseignement"
            :error-messages="errors.collect(`degré d'enseignement`)"
            return-object
            :disabled="isDisplayMode"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="conseilLocal.secteur_geographique"
            v-validate:secteurGeographiqueId="{ is_not: 0 }"
            :items="currentSecteursGeographique"
            :item-text="renderSecteurGeographiqueItem"
            label="Secteur géographique *"
            data-vv-name="secteur géographique"
            :error-messages="errors.collect('secteur géographique')"
            return-object
            :disabled="isDisplayMode"
          />
        </v-col>
      </v-row>
    </div>
    <h1 class="title">
      Adresse
    </h1>
    <div>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="conseilLocal.adresse_legale.ligne1"
            v-validate="'required|min:2|max:50'"
            label="Adresse postale *"
            data-vv-name="adresse postale"
            :error-messages="errors.collect('adresse postale')"
            :disabled="isDisplayMode"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="conseilLocal.adresse_legale.ligne2"
            v-validate="'max:50'"
            label="Adresse complémentaire"
            data-vv-name="adresse postale complement"
            :error-messages="errors.collect('adresse postale complement')"
            :disabled="isDisplayMode"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <CommuneAutocomplete
            :loaded-commune="commune"
            :required="true"
            :disabled="isDisplayMode"
            @changed="communeHasChanged"
          />
        </v-col>
      </v-row>
    </div>
    <h1 class="title">
      Contact
    </h1>
    <div>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="conseilLocal.contact.telephone_fixe"
            v-validate="'phoneNumber'"
            v-mask="'##.##.##.##.##'"
            label="Téléphone fixe"
            data-vv-name="téléphone fixe"
            :error-messages="errors.collect('téléphone fixe')"
            :disabled="isDisplayMode"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="conseilLocal.contact.telephone_mobile"
            v-validate="'phoneNumber'"
            v-mask="'##.##.##.##.##'"
            label="Téléphone portable"
            data-vv-name="téléphone portable"
            :error-messages="errors.collect('téléphone portable')"
            :disabled="isDisplayMode"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="conseilLocal.contact.email"
            v-validate="'email'"
            label="Email"
            data-vv-name="email"
            :error-messages="errors.collect('email')"
            :disabled="isDisplayMode"
          />
        </v-col>
      </v-row>
    </div>
    <div v-if="editMode">
      <h1 class="title">
        Statistiques
      </h1>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="conseilLocal.nb_adherents"
            readonly
            label="Nombre d'adhérents"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="conseilLocal.nbr_rp_service"
            readonly
            label="Abo RP Service"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="conseilLocal.nbr_rp_direct"
            readonly
            label="Abo RP Direct"
          />
        </v-col>
      </v-row>
    </div>


    <h1 class="title">
      Commentaire
    </h1>
    <div>
      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="conseilLocal.commentaire"
            :counter="1000"
            append-icon="edit"
            label="Votre commentaire"
            :disabled="isDisplayMode"
          />
        </v-col>
      </v-row>
    </div>

    <v-btn
      v-if="!isDisplayMode"
      type="submit"
      :disabled="!formValid"
      color="primary"
    >
      {{ editMode ? "Enregistrer ce conseil local et Fermer": "Créer un conseil local" }}
      <v-icon
        right
        dark
      >
        edit
      </v-icon>
    </v-btn>
    <v-btn
      v-if="editMode && !isDisplayMode"
      class="ml-4"
      color="primary"
      :disabled="isDisplayMode"
      @click="returnOnList"
    >
      Fermer
      <v-icon
        right
        dark
      >
        close
      </v-icon>
    </v-btn>
  </form>
</template>
<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
import CommuneAutocomplete from '../partials/CommuneAutocomplete';

export default {
  name: 'ConseilLocalForm',
  components: {
    CommuneAutocomplete,
  },

  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    displayMode: {
      type: Boolean,
      default: false,
    },
    currentCdpeId: {
      type: Number,
      default: 0,
    },
    currentConseilLocalId: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters('session', {
      isUserNational: 'isUserNational',
    }),
    ...mapState('conseilLocal', {
      conseilLocal: state => state.currentConseilLocal,
      currentDegresEnseignement: state => state.currentDegresEnseignement,
      currentSecteursGeographique: state => state.currentSecteursGeographique,
    }),

    ...mapGetters('conseilLocal', {
      statutsJuridique: 'getStatutsJuridique',
    }),

    commune: {
      get() {
        return this.conseilLocal.adresse_legale.commune;
      },
      set(commune) {
        this.$store.commit('conseilLocal/setCommune', commune);
      },
    },


    formValid() {
      // loop over all contents of the fields object and check if they exist and valid.
      return Object.keys(this.fields).every(field => {
        return this.fields[field] && this.fields[field].valid;
      });
    },

    nomCourt() {
      return this.conseilLocal.degre_enseignement.libelle
          .substring(0, 3)
          .toUpperCase()
          ;
    },
    statutJuridiqueId() {
      return this.conseilLocal.statut_juridique.statut_juridique_id;
    },
    degreEnseignementId() {
      return this.conseilLocal.degre_enseignement.id;
    },
    secteurGeographiqueId() {
      return this.conseilLocal.secteur_geographique.id;
    },
    isDisplayMode() {
      return this.displayMode || (!this.isUserNational &&  this.conseilLocal.code.match(/\d\d\d999999/));
    },
  },

  watch: {
    currentConseilLocalId(value) {
      if (!value) {

        this.$nextTick(() => {
          this.$nextTick(() => {
            this.errors.clear();
          });
        });
      }
    },
  },

  methods: {
    ...mapActions('conseilLocal', {
      createConseilLocal: 'create',
      saveConseilLocal: 'save',
    }),
    ...mapActions('session', {
      setSuccess: 'setSuccess',
    }),
    sendConseilLocal() {
      this.$validator.validateAll();
      if (!this.formValid) {
        return;
      }

      let conseilLocal = {
        adresse_legale: {
          ligne1: this.conseilLocal.adresse_legale.ligne1,
          ligne2: this.conseilLocal.adresse_legale.ligne2,
          commune_id: this.commune ?
            this.commune.id:
            this.conseilLocal.adresse_legale.commune.id,
        },
        degre_enseignement_id: this.conseilLocal.degre_enseignement.id,
        secteur_geographique_id: this.conseilLocal.secteur_geographique.id,
        statut_juridique_id: this.conseilLocal.statut_juridique.statut_juridique_id,
        nom: this.conseilLocal.nom,
        contact: {
          telephone_fixe: this.conseilLocal.contact.telephone_fixe,
          telephone_mobile: this.conseilLocal.contact.telephone_mobile,
          email: this.conseilLocal.contact.email,
        },
        commentaire: this.conseilLocal.commentaire,
      };

      if (this.editMode) {
        this.saveConseilLocal({
          cdpeId: this.currentCdpeId,
          conseilLocal,
          conseilLocalId: this.conseilLocal.id,
        }).then (() => {
          this.setSuccess({ info: 'Le conseil local a bien été sauvegardé' });
          if (!this.displayMode) {
            this.returnOnList();
          }
        });
      } else {
        this.createConseilLocal({
          cdpeId: this.currentCdpeId,
          conseilLocal,
        }).then (({ id, code }) => {
          this.setSuccess({ info: `Le conseil local ${code} a bien été créé` });

          this.$router.push({
            name: 'edition-conseil-local',
            params: {
              conseilLocalId: id,
              cdpeId: this.currentCdpeId,
            },
          });
        });
      }
    },

    returnOnList() {
      this.$router.push({
        name: 'recherche-conseil-local',
      });
    },

    communeHasChanged(val) {
      this.commune = val;
    },

    renderSecteurGeographiqueItem(item) {
      return `${item.lettre}${item.libelle ? ' (' + item.libelle + ')' : ''} `;
    },
  },
};
</script>
