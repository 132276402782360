<template>
  <v-stepper
    v-model="step"
    value="1"
  >
    <v-stepper-header>
      <v-stepper-step
        class="subheading"
        editable
        complete
        step="1"
      >
        {{ displayMode ? "Mon conseil local" : editMode ? "Editer le conseil local" : "Créer un conseil local" }}
      </v-stepper-step>

      <v-divider />

      <v-stepper-step
        class="subheading"
        :editable="editMode || displayMode"
        :complete="editMode || displayMode"
        step="2"
      >
        {{ displayMode ? "Les établissements scolaires rattachés" : "Rattacher les établissements scolaires" }}
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <div v-if="isUserNational && !editMode">
          <v-alert
            color="primary"
            dense
            type="info"
            :value="!currentCdpeId"
          >
            Veuillez sélectionner un
            <strong>Département</strong>
          </v-alert>
          <div class="text-right">
            <v-autocomplete
              v-model="currentCdpeId"
              label="Département"
              item-value="id"
              :items="filterDepartements"
              :item-text="(item) => `${item.code} - ${item.nom}`"
            />
          </div>
        </div>
        <ConseilLocalForm
          v-if="currentCdpeId"
          :edit-mode="editMode"
          :display-mode="displayMode"
          :current-cdpe-id="currentCdpeId"
          :current-conseil-local-id="currentConseilLocalId"
        />
      </v-stepper-content>
      <v-stepper-content step="2">
        <EtablissementForm v-show="!displayMode" />
        <EtablissementListe
          :current-conseil-local-id="currentConseilLocalId"
          :current-cdpe-id="currentCdpeId"
          :displayMode="displayMode"
        />
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import ConseilLocalForm from '../../components/conseilLocal/Form';
import EtablissementForm from '../../components/conseilLocal/etablissement/Form';
import EtablissementListe from '../../components/conseilLocal/etablissement/Liste';
import { mapActions, mapGetters } from 'vuex';
import store from '@/store';

export default {
  name: 'ConseilLocalFormContainer',
  components: {
    ConseilLocalForm,
    EtablissementForm,
    EtablissementListe,
  },

  data: () => ({
    step: 1,
    currentCdpeId: 0,
    currentConseilLocalId: 0,
  }),
  async beforeRouteEnter(to, from, next) {
    let cdpeId, conseilLocalId;
    store.dispatch('conseilLocal/loadStatutJuridique');
    if (to.name === 'editer-mon-conseil-local' || to.name === 'voir-mon-conseil-local') {
      cdpeId = store.getters.getUserCdpeId ? store.getters.getUserCdpeId : store.getters['session/getPerimetreUser'].perimetre_appartenance_id;
      conseilLocalId = store.getters.getPerimetreUser ? store.getters.getPerimetreUser.perimetre_id : store.getters['session/getPerimetreUser'].perimetre_id;
    } else {
      if (to.params.cdpeId !== undefined &&
          to.params.conseilLocalId !== undefined
      ) {
        cdpeId = parseInt(to.params.cdpeId, 10),
        conseilLocalId = parseInt(to.params.conseilLocalId, 10);
      }
    }
    if (cdpeId !== undefined && conseilLocalId !== undefined) {
      store.dispatch(
          'conseilLocal/loadConseilLocal',
          {
            cdpeId,
            conseilLocalId,
          }
      );
      store.dispatch(
          'conseilLocal/etablissements/loadAssigned',
          {
            cdpeId,
            conseilLocalId,
          }
      );
      store.dispatch(
          'conseilLocal/loadDegreEnseignement',
          { cdpeId }
      );
      store.dispatch(
          'conseilLocal/loadSecteurGeographique',
          { cdpeId }
      );
      store.dispatch(
          'conseilLocal/etablissements/loadEtablissementsByCdpeId',
          { cdpeId }
      );
    }
    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.dispatch('conseilLocal/resetState');
    next();
  },

  computed: {
    ...mapGetters('session', {
      isUserNational: 'isUserNational',
      perimetreUser: 'getPerimetreUser',
      userCdpeId: 'getUserCdpeId',
    }),
    ...mapGetters('cdpe', {
      filterDepartements: 'getFilterDepartements',
    }),

    editMode() {
      return this.$route.name === 'editer-mon-conseil-local' || this.$route.params.hasOwnProperty('conseilLocalId');
    },

    displayMode() {
      return this.$route.name === 'voir-mon-conseil-local';
    },
  },

  watch: {
    currentCdpeId(val) {
      this.onCdpeIdChange(val);
    },

    '$route.params.conseilLocalId': {
      immediate: true,
      handler(conseilLocalId) {
        this.currentConseilLocalId = parseInt(conseilLocalId, 10);

        if (this.isUserNational && !conseilLocalId) {
          return;
        }
        this.currentCdpeId = this.isUserNational ?
            parseInt(this.$route.params.cdpeId, 10) :
            this.userCdpeId;
      },
    },
  },

  created() {
    if (this.isUserNational && !this.editMode) {
      this.loadFilterDepartements();
    }
  },

  methods: {
    ...mapActions('conseilLocal', [
        'loadConseilLocal',
        'loadDegreEnseignement',
        'loadSecteurGeographique',
        'loadStatutJuridique',
    ]),
    ...mapActions('conseilLocal/etablissements', {
      loadEtablissements: 'loadEtablissementsByCdpeId',
    }),

    ...mapActions('cdpe', {
      loadFilterDepartements: 'loadFilterDepartements',
    }),

    onCdpeIdChange(cdpeId) {
      this.loadDegreEnseignement({
        cdpeId,
      });

      this.loadSecteurGeographique({
        cdpeId,
      });

      this.loadEtablissements({
        cdpeId,
      });
    },
  },
};
</script>