<template>
  <div>
    <v-data-table
      :items="etablissements"
      :headers="headers"
      no-data-text="Aucun établissement"
    >
      <template v-slot:item.action="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click.stop
            >
              more_vert
            </v-icon>
          </template>
          <v-list v-show="!displayMode">
            <v-list-item @click="removeFromAssigned(item.id)">
              <v-list-item-action>
                Retirer
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              v-if="!item.principal"
              @click="makePrincipal(item)"
            >
              <v-list-item-action>
                Rendre principal
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.principal="{item}">
        <span v-if="item.principal">
          Oui
        </span>
      </template>
    </v-data-table>

    <v-btn
      v-if="!displayMode"
      :disabled="!canUpdateAssignedEtablissements"
      color="primary"
      @click="assignEtablissements"
    >
      Rattacher ces établissements
    </v-btn>
  </div>
</template>
<script>

import { mapActions } from 'vuex';

export default {
  name: 'EtablissementListe',

  props: {
    currentCdpeId: {
      type: Number,
      default: 0,
    },
    currentConseilLocalId: {
      type: Number,
      default: 0,
    },
    displayMode: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    headers: [
      {
        text: 'Action',
        sortable: true,
        value: 'action',
      },
      {
        text: 'Nom',
        sortable: true,
        value: 'nom',
      },
      {
        text: 'Etablissement principal',
        sortable: true,
        value: 'principal',
      },
      {
        text: 'Commune',
        sortable: true,
        value: 'ville',
      },
    ],
  }),

  computed: {
    etablissements: {
      get() {
        return this.$store.state.conseilLocal.etablissements.assignedEtablissements;
      },

      set(etablissements) {
        this.$store.commit('conseilLocal/etablissements/setAssignedEtablissements', etablissements);
      },
    },

    /**
     * Can only update if one etablissement is set as principal
     * @returns {*}
     */
    canUpdateAssignedEtablissements() {
      return this.etablissements.some((etablissement) => {
        return etablissement.principal;
      });
    },
  },

  methods: {
    ...mapActions('conseilLocal/etablissements', [
        'saveAssignedEtablissements',
    ]),
    ...mapActions('session', {
      setSuccess: 'setSuccess',
    }),
    assignEtablissements() {
      if (!this.canUpdateAssignedEtablissements) {
        throw new Error('Cannot update assigned etablissements right now');
      }

      let etablissementPrincipal = this.etablissements.find(etablissement => etablissement.principal);

      this.saveAssignedEtablissements({
        conseilLocalId: this.currentConseilLocalId,
        cdpeId: this.currentCdpeId,
        data: {
          etablissement_principal_id: etablissementPrincipal.id,
          etablissements: this.etablissements.map(etablissement => ({ id: etablissement.id })),
        },
      }).then(() => {
        this.setSuccess({ info: 'Les établissements assignés ont bien été mis à jour' });
      });
    },

    removeFromAssigned(etablissementId) {
      this.etablissements = this.etablissements.filter((etablissement) => {
        return etablissement.id !== etablissementId;
      });
    },

    makePrincipal(etablissement) {
      this.etablissements = this.etablissements.map(_etablissement => {
        return {
          ..._etablissement,
          principal: etablissement.id === _etablissement.id,
        };
      });

    },
  },
};
</script>