<template>
  <form
    @submit.prevent="assignEtablissement"
  >
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-autocomplete
          v-model="chosenEtablissement"
          :items="availableEtablissements"
          :item-text="item => `${item.nom} (${item.code_postal} ${item.ville})`"
          label="Sélectionner un établissement scolaire"
          clearable
          return-object
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-btn
          type="submit"
          color="primary"
          :disabled="!chosenEtablissement"
        >
          Ajouter
        </v-btn>
      </v-col>
    </v-row>
  </form>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'EtablissementForm',
  data: () => ({
    chosenEtablissement: null,
  }),

  computed: {
    ...mapGetters('conseilLocal/etablissements', {
      availableEtablissements: 'getAvailableEtablissements',
    }),
    ...mapState('conseilLocal/etablissements', {
      assignedEtablissements: state => state.assignedEtablissements(),
    }),
    ...mapState('conseilLocal', [
      'currentConseilLocal',
    ]),
  },

  methods: {
    assignEtablissement() {
      if (this.chosenEtablissement) {
        this.$store.commit('conseilLocal/etablissements/addEtablissementToAssigned', this.chosenEtablissement);
        this.chosenEtablissement = null;
      }
    },
  },
};
</script>